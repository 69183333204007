import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const Container = styled.div`
  box-shadow: ${props => props.theme.imageBoxShadow};
`
const GalleryImageLink = ({ category, name, fluid }) => {
  return (
    <Link to={`/gallery/${category}/${name}/`}>
      <Container>
        <Img fluid={fluid} />
      </Container>
    </Link>
  )
}

export default GalleryImageLink
