import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import SEO from "../../components/seo"
import GalleryLayout from "../../layouts/galleryLayout"
import GalleryImageLink from "../../components/galleryImageLink"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ImageContainer = styled(motion.div)`
  width: 100%;
  padding: 1.5em 0;

  :first-of-type {
    padding-top: 1rem;
  }

  @media (max-width: 700px) {
    padding: 1rem 0;
  }
`

export default function List({ data, location }) {
  const [nodes] = data.allGalleryJson.edges
  const { category, description, title, hero } = nodes.node

  return (
    <GalleryLayout>
      <SEO
        description={description}
        title={title}
        image={hero.cloudinary.fixed.src}
        pathname={location.pathname}
      />
      <Container>
        {nodes.node.images.map((node, index) => {
          return (
            <ImageContainer
              key={node.name}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0 + index * 0.1 }}
            >
              <GalleryImageLink
                index={index}
                category={category}
                name={node.name}
                fluid={node.src.cloudinary.fluid}
              />
            </ImageContainer>
          )
        })}
      </Container>
    </GalleryLayout>
  )
}

export const query = graphql`
  query($category: String!) {
    allGalleryJson(filter: { category: { eq: $category } }) {
      edges {
        node {
          category
          title
          description
          hero {
            cloudinary: childCloudinaryAsset {
              fixed(transformations: ["t_og_image", "f_jpg"]) {
                ...CloudinaryAssetFixed
              }
            }
          }
          images {
            location
            name
            src {
              cloudinary: childCloudinaryAsset {
                fluid(
                  maxWidth: 1200
                  transformations: ["q_auto:best", "f_auto"]
                ) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
